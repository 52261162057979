<template>
  <div>
    <div class="uk-section uk-padding-remove-vertical uk-margin-remove-vertical in-equity-breadcrumb">
      <div class="uk-container">
        <div class="uk-grid">
          <div class="uk-width-1-1">
            <ul class="uk-breadcrumb">
              <li><router-link :to="{ name: 'public.home' }">Home</router-link></li>
              <li><span>Community</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        uk-section
        in-equity-11
        uk-background-contain uk-background-top-right
      "
      data-src="img/in-equity-11-bg.png"
      data-uk-img=""
      style="background-image: url('/new/in-equity-11-bg.png')"
    >
      <div class="uk-container uk-margin-top uk-margin-bottom">
        <div class="uk-width-3-4@m">
          <div
            class="
              uk-grid-medium
              uk-grid-match
              uk-child-width-1-3@m
              uk-child-width-1-2@s
              uk-grid
            "
            data-uk-grid=""
          >
            <div class="uk-width-1-1 uk-first-column">
              <h1 class="uk-margin-small-bottom">
                Engage in the most
                <span class="in-highlight">popular markets</span>
              </h1>
              <p class="uk-text-lead uk-margin-remove-top">
                Join the community for tips and discussions
              </p>
              <p>
                <a v-if="user.has_completed_first_assessment" href="#membership-plans" class="uk-button uk-button-primary">View Membership Plans</a>
              </p>
            </div>
            <div class="uk-grid-margin uk-first-column">
              <div
                class="
                  uk-card
                  uk-card-default
                  uk-card-body
                  uk-border-rounded
                  uk-box-shadow-medium
                "
              >
                <div class="uk-flex uk-flex-middle">
                  <div class="uk-margin-right">
                    <img
                      src="/new/in-equity-11-icon-1.svg"
                      data-src="img/in-equity-11-icon-1.svg"
                      alt="icon-1"
                      width="128"
                      data-height=""
                      data-uk-img=""
                    />
                  </div>
                  <div>
                    <h5 class="uk-margin-small-bottom">Forex</h5>
                    <p class="uk-text-small uk-margin-remove">
                      Trade 40+ major, minor, and exotic currency pairs
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="uk-grid-margin">
              <div
                class="
                  uk-card
                  uk-card-default
                  uk-card-body
                  uk-border-rounded
                  uk-box-shadow-medium
                "
              >
                <div class="uk-flex uk-flex-middle">
                  <div class="uk-margin-right">
                    <img
                      src="/new/in-equity-11-icon-3.svg"
                      data-src="img/in-equity-11-icon-3.svg"
                      alt="icon-3"
                      width="128"
                      data-height=""
                      data-uk-img=""
                    />
                  </div>
                  <div>
                    <h5 class="uk-margin-small-bottom">Stocks</h5>
                    <p class="uk-text-small uk-margin-remove">
                      Trade the most covered &amp; highest-profile asset classes
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="uk-grid-margin">
              <div
                class="
                  uk-card
                  uk-card-default
                  uk-card-body
                  uk-border-rounded
                  uk-box-shadow-medium
                "
              >
                <div class="uk-flex uk-flex-middle">
                  <div class="uk-margin-right">
                    <img
                      src="/new/in-equity-11-icon-5.svg"
                      data-src="img/in-equity-11-icon-5.svg"
                      alt="icon-5"
                      width="128"
                      data-height=""
                      data-uk-img=""
                    />
                  </div>
                  <div>
                    <h5 class="uk-margin-small-bottom">Cryptos</h5>
                    <p class="uk-text-small uk-margin-remove">
                      Trade Bitcoin, Ethereum, Ripple and others.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="uk-width-1-1 uk-grid-margin uk-first-column" v-if="! user.has_completed_first_assessment">
              <p class="uk-text-small uk-text-muted">
                <span
                  class="
                    uk-label uk-label-info uk-margin-small-right
                    in-label-small
                  "
                  >Note</span
                >To join the community, you need to do an 8-week Course. This helps all our community members to interact as they approach a trade using similar strategies and ideas. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-section in-equity-3 in-offset-top-20">
      <div class="uk-container uk-margin-large-bottom">
        <div class="uk-grid uk-flex uk-flex-middle">
          <div class="uk-width-expand@m">
            <h1 class="uk-margin-small-bottom">
              Our
              <span class="in-highlight">Membership</span> Plans
            </h1>
            <ul class="uk-list in-list-check">
              <li>Free Advanced course</li>
              <li>Free trading signals</li>
              <li>Free trade discussions with senior traders</li>
              <li>Daily conference meetings</li>
              <li>Free correlation numbers</li>
              <li>Free post analysis video from our senior traders.</li>
              <li>Traders ( engagement) awards</li>
            </ul>
          </div>
          <div class="uk-width-2xlarge uk-flex uk-flex-right uk-flex-center@s">
            <div
              class="
                uk-card uk-card-body uk-card-default uk-border-rounded
                in-margin-top-60@s
              "
            >
              <div id="tradingview-widget">
                <div style="width: 100%">
                  <table class="pricing-table" id="membership-plans">
                    <tbody>
                      <tr>
                        <td
                          style="
                            border-bottom: solid 1px #ddd;
                            border-right: solid 1px #ddd;
                          "
                        >
                          <h3>1 Month</h3>
                          <h1>
                            $ {{ membership_plans[1].whole }}
                            <small>.{{ membership_plans[1].cent }}</small>
                          </h1>
                          <router-link
                            v-if="user.has_completed_first_assessment"
                            :to="{ name: 'guest.community.subscribe', params: { id: membership_plans[1].id }}"
                            class="uk-button uk-button-primary uk-button-small uk-margin-remove"
                            >Select</router-link>
                        </td>
                        <td style="border-bottom: solid 1px #ddd">
                          <h3>3 Months</h3>
                          <h1>
                            $ {{ membership_plans[3].whole }}
                            <small>.{{ membership_plans[3].cent }}</small>
                          </h1>
                          <router-link
                            v-if="user.has_completed_first_assessment"
                            :to="{ name: 'guest.community.subscribe', params: { id: membership_plans[3].id }}"
                            class="uk-button uk-button-primary uk-button-small uk-margin-remove"
                            >Select</router-link>
                        </td>
                      </tr>
                      <tr>
                        <td style="border-right: solid 1px #ddd">
                          <h3>6 Months</h3>
                          <h1>
                            $ {{ membership_plans[6].whole }}
                            <small>.{{ membership_plans[6].cent }}</small>
                          </h1>
                          <router-link
                            v-if="user.has_completed_first_assessment"
                            :to="{ name: 'guest.community.subscribe', params: { id: membership_plans[6].id }}"
                            class="uk-button uk-button-primary uk-button-small uk-margin-remove"
                            >Select</router-link>
                        </td>
                        <td>
                          <h3>12 Months</h3>
                          <h1>
                            $ {{ membership_plans[12].whole }}
                            <small>.{{ membership_plans[12].cent }}</small>
                          </h1>
                          <router-link
                            v-if="user.has_completed_first_assessment"
                            :to="{ name: 'guest.community.subscribe', params: { id: membership_plans[12].id }}"
                            class="uk-button uk-button-primary uk-button-small uk-margin-remove"
                            >Select</router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        uk-section uk-section-primary uk-preserve-color
        in-equity-6
        uk-background-contain uk-background-center
      "
      data-src="/new/in-equity-decor-2.svg"
      data-uk-img=""
      style="background-image: url('/new/in-equity-decor-2.svg')"
    >
      <div class="uk-container uk-margin-small-bottom">
        <div class="uk-grid uk-flex uk-flex-center">
          <div class="uk-width-3-4@m uk-margin-top">
            <div
              class="
                uk-grid uk-child-width-1-3@s uk-child-width-1-3@m uk-text-center
                in-register
              "
              data-uk-grid=""
            >
              <div class="uk-width-1-1 in-h-line uk-first-column">
                <h2>Getting started is easy</h2>
              </div>
              <div class="uk-grid-margin uk-first-column">
                <span class="in-icon-wrap circle">1</span>
                <p>Create an account</p>
              </div>
              <div class="uk-grid-margin">
                <span class="in-icon-wrap circle">2</span>
                <p>
                  Take the first course in the academy to learn the
                  fundamentals.
                </p>
              </div>
              <div class="uk-grid-margin">
                <span class="in-icon-wrap circle">3</span>
                <p>Join the community</p>
              </div>
              <div class="uk-width-1-1 uk-grid-margin uk-first-column">
                <router-link
                  :to="{ name: 'register' }"
                  class="uk-button uk-button-text"
                  >Open account<i
                    class="fas fa-arrow-circle-right uk-margin-small-left"
                  ></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      membership_plans: { 1: {}, 3: {}, 6: {}, 12: {}},
    };
  },

  methods: {
    fetch() {
      this.$axios.get("/api/v1/community-pricing").then((response) => {
        this.membership_plans = response.data.membership_plans;
      });
    },
  },

  computed: {
    user() {
      return this.$store.getters.getAuthUser;
    },
  },
};
</script>
